<template>
  <div class="setting">
    <title-top>设置</title-top>
    <div class="row between align-center bg-white h118 position_r">
      <span class="font28 f-333333 fontweight400 ml25">头像设置</span>
      <img class="imgsize96 br100 mr25" :src="(res.avatar.url || '')" alt />
      <input
        type="file"
        name
        class="file"
        accept="image/gif, image/jpeg, image/jpg, image/png"
        @change="fileChange"
      />
    </div>
    <div class="line"></div>
    <div class="row between align-center bg-white h96" @click="onNick">
      <span class="font28 f-333333 fontweight400 ml25">昵称设置</span>
      <div class="row flex-center">
        <span class="font24 f-999999">{{res.name}}</span>
        <img class="imgsize32 ml16 mr25" src="~images/nextr.png" alt />
      </div>
    </div>
    <div class="line"></div>

    <div class="row between align-center bg-white h96" @click="onPhone">
      <span class="font28 f-333333 fontweight400 ml25">手机号绑定</span>
      <div class="row flex-center">
        <span class="font24 f-999999">修改手机号</span>
        <img class="imgsize32 ml16 mr25" src="~images/nextr.png" alt />
      </div>
    </div>
    <div class="line"></div>
    <div class="row between align-center bg-white h96" @click="onLogpsw">
      <span class="font28 f-333333 fontweight400 ml25">登录密码</span>
      <div class="row flex-center">
        <span class="font24 f-999999">修改密码</span>
        <img class="imgsize32 ml16 mr25" src="~images/nextr.png" alt />
      </div>
    </div>
    <div class="line"></div>
    <div class="row between align-center bg-white h96" @click="onPaypsw">
      <span class="font28 f-333333 fontweight400 ml25">设置支付密码</span>
      <div class="row flex-center">
        <span class="font24 f-999999"></span>
        <img class="imgsize32 ml16 mr25" src="~images/nextr.png" alt />
      </div>
    </div>
    <div class="row flex-center" @click="onLogout">
      <div class="btn-red mt170">退出登录</div>
    </div>

    <div class="column flex-center model-mask" v-if="isShow">
      <div class="column align-center whitescreen font32 f-1A1A1A" style="width: 7.893333rem;">
        <span class="row just-center h98 fontwb" style="align-items: flex-end;">请输入新的昵称</span>
        <span class="row flex-center h72 font24 f-999999">2~10字之间，支持中英文、数字或下划线</span>
        <input class="area font28 mt40 mb40" style="padding-left:10px;" v-model="nickname" />
        <div class="line"></div>
        <div class="row flex-center h96">
          <span
            class="row flex-center f-999999 h96"
            style="width: 3.946667rem;border-right: .013333rem solid #E6E6E6;"
            @click="onCancle"
          >取消</span>
          <span class="flex1 row flex-center" style="width: 3.946667rem;" @click="onSure">确定</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import SetTitle from './childCmps/SetTitle'
import TitleTop from "@/components/common/title/TitleTop";
import {
  member,
  update_member,
  upload_img,
  upload_header,
  logout
} from "network/igou";
export default {
  name: "Setting",
  components: {
    TitleTop
  },
  data() {
    return {
      isShow: false,
      nickname: "",
      res: { avatar: { url: "" } }
    };
  },
  mounted() {
    this.get_member();
  },
  methods: {
    onLogout() {
      logout().then(res => {
        this.$router.back();
      });
    },
    //头像选择
    fileChange(e) {
      var that = this;
      var file = e.target.files[0]; //本地路径
      var reader = new FileReader();
      reader.onload = function(e) {
        that.res.avatar.url = e.target.result; //base64格式
        upload_img(file)
          .then(res => {
            //图片上传
            return upload_header(res.data.id);
          })
          .then(res => {
            that.get_member();
          });
      };
      reader.readAsDataURL(file);
    },
    get_member() {
      member().then(res => {
        this.res = res.data;
      });
    },
    onNick() {
      this.isShow = true;
    },
    onCancle() {
      this.isShow = false;
    },
    onSure() {
      this.isShow = false;
      if (!this.nickname) {
        this.$toast.show("请填写昵称");
        return;
      }
      //修改昵称
      update_member(this.nickname).then(res => {
        this.$toast.show("修改成功");
        this.nickname = "";
        this.get_member();
      });
    },
    onLogpsw() {
      this.$router.push("/xgpsw");
    },
    onPaypsw() {
      this.$router.push("/xgpaypsw");
    },
    onPhone() {
      this.$router.push("/xgphone");
    }
  }
};
</script>
<style  scoped>
.file {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
}
.tsbtn {
  background: #46ca78;
  width: 1.6rem;
  height: 0.8rem;
}
.circle {
  background: #fefefe;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 100%;
}
.clear-btn {
  border: 0.013333rem solid #f62341;
  width: 1.6rem;
  height: 0.8rem;
}
.area {
  border: none;
  outline: none;
  width: 6.586667rem;
  height: 1.28rem;
  background: rgba(230, 230, 230, 1);
  border-radius: 0.266667rem;
}
</style>
